import { MsalApplicationModule } from "./core/msal-application-module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { MsalRedirectComponent } from "@azure/msal-angular";

@NgModule({
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    BfcTranslationModule,
    BfcConfigurationModule,
    BfcNotificationModule,
    BfcGoogleAnalyticsModule,
    BfcAuthenticationModule,
    AppRoutingModule,
    MsalApplicationModule,
    BfcSinglePageLayoutModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
