import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { VoteService } from "./vote.service";

@Injectable()
export class AlreadyVotedGuard implements CanActivate {
  constructor(private router: Router,
    private voteService: VoteService) {
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.voteService.hasVoted(this.voteService.username).subscribe(
        (alreadyVoted) => {
          if (alreadyVoted) {
            this.router.navigate(["already-voted"]);
            resolve(false);
          } else {
            resolve(true);
          }
        },
        (error: unknown) => {
          resolve(false);
          throw error;
        },
      );
    });
  }
}
