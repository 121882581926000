import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { AlreadyVotedGuard } from "./services/already-voted-guard.service";
import { CandidatesService } from "./services/candidates.service";
import { GlobalErrorHandler } from "./services/global-error-handler.service";
import { PicturesService } from "./services/pictures.service";
import { VoteService } from "./services/vote.service";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    InitializerProvider,
    CandidatesService,
    AlreadyVotedGuard,
    PicturesService,
    VoteService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
