import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { T_ENV } from "./environments/t";
import { Q_ENV } from "./environments/q";
import { P_ENV } from "./environments/p";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { Configuration } from "@azure/msal-browser";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface IPevAppConfiguration {
  googleTagManagerContainerId?: string;
  lastVotingDay?: string;
  voteEndDate?: string;
  loginUrl?: string;
  logoutUrl?: string;
  pekoApiUrl?: string;
  msalConfig?: Configuration;
  msalAngularIntercepterConfig?: MsalInterceptorConfiguration;
  msalAngularGuardConfig?: MsalGuardConfiguration;
  maxVotes?: {
    step_2: number,
    step_3: number,
    step_4: number
  }
}
