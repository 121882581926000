import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ICandidate } from "../../model/candidate";

@Injectable()
export class CandidatesService {

  constructor(private configService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  getCandidates(): Observable<ICandidate[]> {
    // return this.httpClient.get(this.configService.configuration.pekoApiUrl + "services/candidate").pipe(
    //     map((response: ICandidate[]) => response)
    // )

    return this.httpClient.get("https://pev-wahlen-t.bkw.ch/api/services/candidate").pipe(
      map((response: ICandidate[]) => response),
    );
  }

  static sortCandidates(c1: ICandidate, c2: ICandidate) {
    //return candidate?.candidateId > otherCandiate?.candidateId ? 1 : candidate?.candidateId < otherCandiate?.candidateId ? -1 : 0
    if (c1.lastName?.toLowerCase() > c2.lastName?.toLowerCase()) {
      return 1;
    } else if (c1.lastName?.toLowerCase() < c2.lastName?.toLowerCase()) {
      return -1;
    } else {
      // the same lastName, so check firstname:
      if (c1.firstName?.toLowerCase() > c2.firstName?.toLowerCase()) {
        return 1;
      } else if (c1.firstName?.toLowerCase() < c2.firstName?.toLowerCase()) {
        return -1;
      }
    }
    return 0;
  }
}
